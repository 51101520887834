import BookIcon from 'src/assets/icons/open-book.svg';
import PhoneIcon from 'src/assets/icons/ringer-volume.svg';
import AppIcon from 'src/assets/icons/push-notifications.svg';

const content = {
  title: 'Betriebliche Krankenversicherung',
  headvisualHeadline:
    'Absicherung für Mitarbeiter - Betriebliche Krankenversicherung',
  uebersichtsSection: {
    headline: 'Weil gesunde Mitarbeiter Ihr größtes Kapital sind',
    content:
      'Mit einer bKV können Sie Ihre Mitarbeiter schon während des aktiven Erwerbslebens mit Sonderleistungen unterstützen, deren Vorteile sie sofort nutzen können. Im Rahmen der bKV können die Leistungen der gesetzlichen Krankenkasse gezielt ergänzt werden – mit privaten Zusatzversicherungen. Um Ihren Mitarbeitern diesen Gesundheitsschutz zu vergünstigten Konditionen anzubieten, schließen Sie mit der NÜRNBERGER Krankenversicherung einen Gruppenvertrag ab.'
  },
  beitraegeSection: {
    leftCard: {
      background: 'light-blue',
      headline: 'Wer zahlt die Beiträge?',
      paragraphs: [
        'Den Beitrag zur bKV können Sie komplett übernehmen, den Beitrag Ihrer Mitarbeiter bezuschussen oder der Beitrag wird durch Ihren Mitarbeiter finanziert.',
        'Bei der arbeitnehmerfinanzierten Variante wird der Beitrag vom Nettogehalt des Arbeitnehmers abgezogen. Selbst damit ermöglichen Sie allen Mitarbeitern den leichteren Zugang zur privaten Krankenversicherung. Und das zu besonders günstigen Konditionen. Auch denen, die z. B. aus gesundheitlichen Gründen Beitragszuschläge zahlen müssten oder überhaupt nicht versicherbar wären.'
      ]
    },
    rightCard: {
      background: 'dark-blue',
      headline: 'bKV für alle – individuell ...',
      content:
        'Welche Leistungen bietet eine bKV? Bei der NÜRNBERGER haben Sie verschiedene Möglichkeiten, das Passende für Ihre Mitarbeiter zu wählen. Sie können einzelne Zusatzversicherungen individuell zusammenstellen oder sich für einen unserer Kompakttarife entscheiden.',
      button: {
        variant: 'ghost',
        text: 'Mehr erfahren',
        href:
          'https://www.nuernberger.de/gewerbe/inhaber-mitarbeiter/betriebliche-krankenversicherung/'
      }
    }
  },
  serviceSection: {
    headline: 'Service inklusive',
    content:
      'Die NÜRNBERGER ist Ihr kompetenter Ansprechpartner, wenn es darum geht, betriebliche Vorsorgekonzepte in Ihrem Unternehmen einzuführen – ohne großen Aufwand für Sie. Unsere Spezialisten kümmern sich um alle Vertrags- und Leistungsangelegenheiten. Zusätzlich haben wir für Sie und Ihre Mitarbeiter attraktive Serviceleistungen rund um die bKV.',
    cards: [
      {
        icon: BookIcon,
        headline: 'So kommt die bKV bei jedem an',
        paragraphs: [
          'Die NÜRNBERGER unterstützt Sie auf Wunsch bei Veranstaltungen und mit Informationsmaterial, das auf Ihr Unternehmen zugeschnitten ist.',
          'Damit Ihre Mitarbeiter die Vorteile der bKV umfassend kennenlernen unddiese nutzen können.'
        ]
      },
      {
        icon: AppIcon,
        headline: 'App zum Abrechnen: "EasyScan"',
        paragraphs: [
          'Rechnung scannen, Porto sparen, schneller erstattet: Mit unserer Rechnungsapp EasyScan können Ihre Mitarbeiter einfach und bequem Rechnungen, Rezepte, Verordnungen, Heil- und Kostenpläne, Bescheinigungen usw. einreichen.',
          'Die App ist natürlich kostenlos und kann auf Smartphones oder Tablets geladen werden.'
        ]
      }
    ]
  },
  vorteileSection: {
    headline: 'Ihre Vorteile auf einen Blick',
    background: 'light-blue',
    items: [
      'Mitarbeiterbindung steigt: Die Mitarbeiter identifizieren sich stärker mit dem Unternehmen – die Fluktuation sinkt.',
      'Eine bKV wirkt nachhaltig – und ist eine wertvolle Alternative zur Gehaltserhöhung oder zum Dienstwagen.',
      'Erhöht Ihre Chancen im Wettbewerb um qualifizierte Fach- und Führungskräfte. Gleichzeitig zeigen Sie soziales Engagement.',
      'Die Kosten für eine bKV sind als Betriebsausgaben absetzbar.',
      'Die Motivation der Mitarbeiter wirkt sich auch auf die Fehlzeiten aus: Mit einer bKV sichern Sie die Leistungsfähigkeit aller.',
      'Sie verbessern den Gesundheitsschutz Ihrer Mitarbeiter.'
    ]
  }
};

export default content;
