import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  HalvedContent,
  ContentBox,
  Button,
  Grid,
  List,
  Paragraphs
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-krankenversicherung';
import subpageNavigations from 'src/data/subpage-navigations';

const BetrieblicheKrankenversicherungPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '85'
    }}
    subpageNavItems={subpageNavigations.absicherungFuerMitarbeiter}
    activeNavItem={3}
    useParentPathname
  >
    <section>
      <SectionHead headline={content.uebersichtsSection.headline} centered>
        <p>{content.uebersichtsSection.content}</p>
      </SectionHead>
      <HalvedContent>
        <ContentBox
          headline={content.beitraegeSection.leftCard.headline}
          background={content.beitraegeSection.leftCard.background}
          align="right"
        >
          <Paragraphs items={content.beitraegeSection.leftCard.paragraphs} />
        </ContentBox>
        <ContentBox
          headline={content.beitraegeSection.rightCard.headline}
          background={content.beitraegeSection.rightCard.background}
          align="left"
        >
          <p>{content.beitraegeSection.rightCard.content}</p>
          <Button
            variant={content.beitraegeSection.rightCard.button.variant}
            href={content.beitraegeSection.rightCard.button.href}
            external
          >
            <p>{content.beitraegeSection.rightCard.button.text}</p>
          </Button>
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <SectionHead headline={content.serviceSection.headline} centered>
        <p>{content.serviceSection.content}</p>
      </SectionHead>
      <Grid>
        {content.serviceSection.cards.map((card, index) => (
          <Card key={index}>
            <CardHeader headline={card.headline}>
              <card.icon />
            </CardHeader>
            <Paragraphs items={card.paragraphs} />
          </Card>
        ))}
      </Grid>
    </section>
    <BackgroundImage
      fluid={data.emptyOffice.childImageSharp.fluid}
      className="full-width-image bg-bottom"
    />
    <section>
      <ContentBox
        background={content.vorteileSection.background}
        headline={content.vorteileSection.headline}
        single
        shifted
      >
        <List items={content.vorteileSection.items} columns={2} />
      </ContentBox>
    </section>
  </MainLayout>
);

export default BetrieblicheKrankenversicherungPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "blond-woman-smiles-wide.jpg" }
    ) {
      ...image
    }
    emptyOffice: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "empty-office.jpg" }
    ) {
      ...image
    }
  }
`;
